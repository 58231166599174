.charts .settings .panel {
    min-height: 600px;
}

.charts .settings .panel .panel-body {
    min-height: 550px;
}

.charts .settings .settings-selection ul ul {
    margin-left: 20px;
}

.charts .settings .settings-selection ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.charts .settings .settings-selection ul p {
    font-weight: bold;
}

.a1, .a2, .a3, .a4, .a5, .a6, .a7, .a8 {
    color: #000;
}

@media only screen and (min-width: 1200px) {
    .charts .settings .panel {
        max-height: 600px;
    }
    .charts .settings .panel .panel-body {
        max-height: 550px;
        overflow: auto;
    }
}
